@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');

@layer components {
	.invisible-scroll::-webkit-scrollbar {
		@apply w-0 h-0
	}
	
	.custom-scroll,
	.custom-scroll-Canvas {
		overflow: auto;
	}
	
	.custom-scroll::-webkit-scrollbar,
	.custom-scroll-Canvas::-webkit-scrollbar {
		width: 16px;
		height: 16px;
	}

	.custom-scrollCanvas::-webkit-scrollbar {
		background-color: theme('backgroundColor.Canvas');
	}
	
	.custom-scroll::-webkit-scrollbar-thumb:vertical {
		border-radius: 4px;
		box-shadow: inset 8px 0 0 white, inset -8px 0 #999;
		transition: all 0.1s;
		-moz-transition: all 0.1s;
		-webkit-transition: all 0.1s;
	}
	
	.custom-scroll::-webkit-scrollbar-thumb:horizontal {
		border-radius: 4px;
		box-shadow: inset 0 8px 0 white, inset 0 -8px #999;
		transition: all 0.1s;
		-moz-transition: all 0.1s;
		-webkit-transition: all 0.1s;
	}
	
	.custom-scroll::-webkit-scrollbar-thumb:vertical:hover {
		border-radius: 4px;
		box-shadow: inset 8px 0 0 white, inset -8px 0 #777;
		transition: all 0.1s;
		-moz-transition: all 0.1s;
		-webkit-transition: all 0.1s;
	}
	
	.custom-scroll::-webkit-scrollbar-thumb:horizontal:hover {
		border-radius: 4px;
		box-shadow: inset 0 8px 0 white, inset 0 -8px #777;
		transition: all 0.1s;
		-moz-transition: all 0.1s;
		-webkit-transition: all 0.1s;
	}

	.custom-scroll-Canvas::-webkit-scrollbar-thumb:vertical {
		border-radius: 4px;
		box-shadow: inset 8px 0 0 theme('backgroundColor.Canvas'), inset -8px 0 #999; /* right aligned */
		transition: all 0.1s;
		-moz-transition: all 0.1s;
		-webkit-transition: all 0.1s;
	}

	.custom-scroll-Canvas::-webkit-scrollbar-thumb:horizontal {
		border-radius: 4px;
		box-shadow: inset 0 8px 0 theme('backgroundColor.Canvas'), inset 0 -8px #999;
		transition: all 0.1s;
		-moz-transition: all 0.1s;
		-webkit-transition: all 0.1s;
	}

	.custom-scroll-Canvas::-webkit-scrollbar-thumb:vertical:hover {
		border-radius: 4px;
		box-shadow: inset 8px 0 0 theme('backgroundColor.Canvas'), inset -8px 0 #777; /* right aligned */
		transition: all 0.1s;
		-moz-transition: all 0.1s;
		-webkit-transition: all 0.1s;
	}

	.custom-scroll-Canvas::-webkit-scrollbar-thumb:horizontal:hover {
		border-radius: 4px;
		box-shadow: inset 0 8px 0 theme('backgroundColor.Canvas'), inset 0 -8px #777;
		transition: all 0.1s;
		-moz-transition: all 0.1s;
		-webkit-transition: all 0.1s;
	}

	.custom-scroll-Canvas::-webkit-scrollbar-corner {
		background: theme('backgroundColor.Canvas');
	}

	.scrollbar>.slider {
		border-radius: 1000px;
	}

	.scrollbar.horizontal>.slider {
		top: -4px !important;
	}


	.editor-label {
		transition: transform 0.5s ease-in-out;
		transform-origin: middle left;
	}

	/* .important-whitespace-nowrap {
        white-space: nowrap !important;
    } */

	.pop-up-scrollbar {
		@apply overflow-auto
	}

	.pop-up-scrollbar::-webkit-scrollbar {
		@apply w-3 h-3
	}

	.pop-up-scrollbar::-webkit-scrollbar-thumb:horizontal {
		border-radius: 3px;
		box-shadow: inset 0 6px 0 white, inset 0 -6px theme('backgroundColor.neutral.200');
		transition: all 0.1s;
		-moz-transition: all 0.1s;
		-webkit-transition: all 0.1s;
	}

	.pop-up-scrollbar::-webkit-scrollbar-thumb:horizontal:hover {
		border-radius: 3px;
		box-shadow: inset 0 6px 0 white, inset 0 -6px theme('backgroundColor.neutral.300');
		transition: all 0.1s;
		-moz-transition: all 0.1s;
		-webkit-transition: all 0.1s;
	}

	.pop-up-scrollbar::-webkit-scrollbar-thumb:vertical {
		border-radius: 3px;
		box-shadow: inset 6px 0 0 white, inset -6px 0 theme('backgroundColor.neutral.200');
		transition: all 0.1s;
		-moz-transition: all 0.1s;
		-webkit-transition: all 0.1s;
	}

	.pop-up-scrollbar::-webkit-scrollbar-thumb:vertical:hover {
		border-radius: 3px;
		box-shadow: inset 6px 0 0 white, inset -6px 0 theme('backgroundColor.neutral.300');
		transition: all 0.1s;
		-moz-transition: all 0.1s;
		-webkit-transition: all 0.1s;
	}

	
	/* block code in assistant chat */
	.chat-md>pre>code.chat-message-code {
		@apply w-full shadow-sm bg-white custom-scroll max-w-[627px] rounded-md p-3 inline-block
	}

	/* inline code in assistant chat */
	.chat-md>p>code.chat-message-code,
	.chat-md>li>code.chat-message-code,
	.chat-md>div>code.chat-message-code {
		@apply shadow-sm bg-white p-1 rounded-md
	}

	.chat-md>ul {
		@apply list-inside;
	}
	.chat-md>ul>li {
		@apply flex;
	}
	.chat-md>ul>li::before {
		content: "•";
		@apply mr-2 font-bold;
	}
	.chat-md>ul>li>p {
		@apply flex-1;
	}

	.chat-md>ol {
		@apply list-inside;
		counter-reset: list-counter;
	}
	.chat-md>ol>li {
		@apply flex;
		counter-increment: list-counter;
	}
	.chat-md>ol>li::before {
		content: counter(list-counter) ".";
		@apply mr-2 font-bold;
	}
	.chat-md>ol>li>p {
		@apply flex-1;
	}
}

@layer utilities {
	.debug {
		/* @apply border-[1px] border-red-500 */
	}
}

.css-vlvsgl * {
	white-space: nowrap !important;
}

@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')